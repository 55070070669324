/* eslint-disable jsx-a11y/heading-has-content*/
import React from "react";
import Logo from "../images/Logo.svg";

export default props => {
  return (
    <div
      className="is-white-bg pad-5-lr pad-3-tb"
      style={{ position: "fixed", width: "100vw", zIndex: 100 }}
    >
      <div className="row flex padding-0-tb container-small">
        <div className="col-xs-4 flex">
          <a
            href="https://sld.codes/"
            title="home"
            className=" align-horizontal is-white flex grow-on-hover"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Logo}
              alt=""
              style={{ paddingBottom: 2, height: "21.5px" }}
            />
          </a>
        </div>
        <div
          className="col-xs-7 flex text-align-right"
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <a
            href={
              "https://medium.com/@samlarsendisney/auditing-the-first-100-pages-of-the-top-100-websites-2e30c4d36847"
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <button type="button" className="btn is-white is-pink-bg">
              Read the article
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
